import { LicenseCode } from "./LicenseCode";
import { LicenseType } from "./LicenseType";

export type LicenseStatistics = {
    licType: LicenseType,
    usrs: string[],
    availableActive: number,
    availableTotal: number,
    codes: LicenseCode[],
}

export const getCollectedLicenses = (lics: LicenseCode[], onlyActive: boolean): Map<number, LicenseStatistics> => {
    let collected = new Map<number, LicenseStatistics>();
    if (lics !== undefined && lics !== null) {
        for (let i = 0; i < lics.length; i++) {
            let licStats: LicenseStatistics;
            let code: LicenseCode = lics[i];
            let isAvailable: boolean = code.licenseStart == null || code.activeNow;
            if (code.activeNow || !onlyActive) {
                if (collected.has(code.licenseType.id!)) {
                    licStats = collected.get(code.licenseType.id!) as LicenseStatistics;
                    licStats.usrs = [...licStats.usrs, ...code.users];
                    if (isAvailable) licStats.availableTotal += code.usersNo;
                    if (code.activeNow) licStats.availableActive += code.usersNo;
                    licStats.codes = [...licStats.codes, code];
                } else {
                    licStats = {
                        licType: code.licenseType,
                        usrs: [...code.users],
                        availableTotal: isAvailable ? code.usersNo : 0,
                        availableActive: code.activeNow ? code.usersNo : 0,
                        codes: [code]
                    };
                    collected.set(licStats.licType.id!, licStats);
                }
            }
        }
    }
    return collected;
}