import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { requestServer } from "../../store/ServerConnection";
import OrdersResponse, { handleUserOrdersResponse } from "../../type/responses/OrdersResponse";
import { UserOrder, handleUserOrderDates } from "../../type/UserOrder";
import SaveInvoiceDataRequest from "../../type/request/SaveInvoiceDataRequest";
import { t } from "i18next";
import { ValidationError } from "../../type/validation/ValidationError";
import { apiErrorHandling } from "../useErrorHandling";
import ConfirmProFormaSentRequest from "../../type/request/ConfirmProFormaSentRequest";
import { queryClient } from "../../App";
import { TANSTACK_USER_ORDERS_KEY } from "../useUserOrders";

export function useConfirmProFormaSentToUser() {
    const {mutationWithErrHandling } = apiErrorHandling({
        errMsg: t("Error while confirming, that pro-forma was sent to user"),
        createRequest: ({orderId}: any) => {
            let body: ConfirmProFormaSentRequest = {
                orderId: orderId,
            };
            return body;
        }
    });

    const { mutate: confirmProFormaSent} = mutationWithErrHandling("confirmProFormaSent",
        (ret: OrdersResponse) => {
            queryClient.invalidateQueries([TANSTACK_USER_ORDERS_KEY]);
            //queryClient.setQueryData([TANSTACK_USER_ORDERS_KEY, ""], handleUserOrdersResponse(ret));
        }
    );
  
    return { confirmProFormaSent };
}

export function useSaveInvoiceData(setUserOrder: (order: UserOrder) => void, hideInvoiceDataPopup: () => void) {
    const { serverError, serverSuccess } = Notifications();
    const [validationError, setValidationError] = React.useState<ValidationError|null>(null);
    const props = React.useContext(AseclaDataContext) as ContextProps;
    
    const { mutate: saveInvoiceData } = useMutation({
        mutationFn: async (req: SaveInvoiceDataRequest) => {
            if (!props.authentication.initialized() || !props.authentication.isAuthenticated()) {
                return new Promise(() => null);
            }
            setValidationError(null);
            return requestServer("saveInvoiceData", req, props);
        },
        onSuccess: (ret: OrdersResponse|ValidationError, req: SaveInvoiceDataRequest) => {
            if (!ret.success) {
                setValidationError(ret as ValidationError);
            } else {
                setUserOrder(handleUserOrderDates((ret as OrdersResponse).orders[0]));
                serverSuccess(t("Invoice data saved"));
                if (req.generateProForma) {
                    hideInvoiceDataPopup();
                }
            }
        },
        onError: () => serverError(t("Error while saving invoice data"))
    });
 
    return { saveInvoiceData, validationError };
}

