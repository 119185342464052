import { t } from "i18next";
import styled from "styled-components";
import { useLicenseTypesOffered } from "../../hooks/useLicenseTypes";
import { LicenseType } from "../../type/LicenseType";
import i18n from "../../i18n";
import TitleBanner from "../utils/TitleBanner";

type AseclaProductsTableParams = {
    onUserOrderSelectByName: (name: string) => void
}
function AseclaProductsTable({onUserOrderSelectByName}: AseclaProductsTableParams) {
    const { licenseTypes, isFetched } = useLicenseTypesOffered();

    const enable = (name: string): boolean => {
        if (licenseTypes != null) {
            for (let i = 0; i < licenseTypes.length; i++) {
                let r: LicenseType = licenseTypes[i];
                if (r.includeInOffer && r.names[i18n.language] === name) {
                    return true;
                }
            }
        }
        return false;
    }

    const ChooseLink = ({ caption }: any) => {
        if (!isFetched) return <></>;
        if (enable(caption)) {
            return <div className="wordField heavyWordField underLineWordField"><a onClick={e => onUserOrderSelectByName(caption)}>{t("Select") as string}</a></div>;
        }
        return <div className="wordField heavyWordField underLineWordField"><span style={{ color: 'gray' }}>{t("Select") as string}</span></div>;
    };

    return <>
        <TitleBanner title={t("Select product")}></TitleBanner>
        <PaddingDiv className="product_pricing_table">
            <div className="Selectable-element column column2" onClick={(e:any) => onUserOrderSelectByName("Silver")}>
                <div className="wordField tableheader">Silver</div>
                <div className="wordField lightWordField">{t("You send occasionally") as string}</div>
                <div className="wordField lightWordField">{t("File up to 50 GB") as string}</div>
                <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                <div className="wordField inlined">
                    <span className="priceWordField">49</span>
                    <span className="lightWordField">{t("PLN / month") as string}</span>
                    <br/>
                    <span className="lightWordField crowdedWordField">{t("per person on an annual basis (net)") as string}</span>
                </div>
                <div className="wordField lightWordField"><span className="heavyWordField">588</span><span className="lightWordField">{t("PLN / year") as string}</span></div>
                <ChooseLink caption="Silver"></ChooseLink>
            </div>
            <div className="Selectable-element column column3" onClick={(e:any) => onUserOrderSelectByName("Gold")}>
                <div className="wordField tableheader">Gold</div>
                <div className="wordField lightWordField">{t("You send regularly") as string}</div>
                <div className="wordField lightWordField">{t("File up to 200 GB") as string}</div>
                <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                <div className="wordField inlined">
                    <span className="priceWordField">69</span>
                    <span className="lightWordField">{t("PLN / month") as string}</span>
                    <br/>
                    <span className="lightWordField crowdedWordField">{t("per person on an annual basis (net)") as string}</span>
                </div>
                <div className="wordField lightWordField"><span className="heavyWordField">828</span><span className="lightWordField">{t("PLN / year") as string}</span></div>
                <ChooseLink caption="Gold"></ChooseLink>
            </div>
            <div className="Selectable-element column column4" onClick={(e:any) => onUserOrderSelectByName("Platinum")}>
                <div className="wordField tableheader">Platinum</div>
                <div className="wordField lightWordField">{t("You are sending intensive") as string}</div>
                <div className="wordField lightWordField">{t("File up to 500 GB") as string}</div>
                <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                <div className="wordField inlined">
                    <span className="priceWordField">99</span>
                    <span className="lightWordField">{t("PLN / month") as string}</span>
                    <br/>
                    <span className="lightWordField crowdedWordField">{t("per person on an annual basis (net)") as string}</span>
                </div>
                <div className="wordField lightWordField"><span className="heavyWordField">1188</span><span className="lightWordField">{t("PLN / year") as string}</span></div>
                <ChooseLink caption="Platinum"></ChooseLink>
            </div>
        </PaddingDiv>
    </>
}
export default AseclaProductsTable;

const PaddingDiv = styled.div`
  padding:25px;
`;
