import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { requestServer } from "../../store/ServerConnection";
import { t } from "i18next";
import ClaimLicenseCodeRequest from "../../type/request/ClaimLicenseCodeRequest";
import { apiErrorHandling } from "../useErrorHandling";

interface redeemParams {
    successAction: () => void
}
interface useRedeemCodeParams {
    code: string,
    
}
export function useRedeemCode({successAction}: redeemParams) {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    const { successHandler } = apiErrorHandling({
        errMsg: t("Could not redeem code")
    });

    const { mutate: redeemCode, isError: isRedeemError, isSuccess: isRedeemSuccess } = useMutation({
        mutationFn: async ({code}: useRedeemCodeParams) => {
            let body: ClaimLicenseCodeRequest = {
                code: code,
                organizationId: props.getCurrentOrganization()?.id!,
                sinceDate: null,
            };
            return requestServer("claimLicense", body, props);
        },
        onSuccess: successHandler((resp: any) => {
            serverSuccess(t("Your code has been redeemed"));
            successAction();
        }),
        onError: (error: any, variables: useRedeemCodeParams, context: any) => {
            console.log(error);
            console.log(variables);
            console.log(context);
            serverError(t("Code Redeem failed"))
        }
    });
  
    return { redeemCode, isRedeemError, isRedeemSuccess };
}
