import styled from "styled-components";

type StepperParams = {
    stepNo: number,
    captions: string[]
}
function Stepper({stepNo, captions}: StepperParams) {
    return <StepperDiv>
        <div className="stepper-wrapper">
            {captions.map((c, index) => <div key={"step" + index} className={"stepper-item " + (index+1 > stepNo ? "active" : "completed")}>
                <StepperTitle className="step-name">{c}</StepperTitle>
                <div className="step-counter">{index+1}</div>
            </div>)}
        </div>
    </StepperDiv>
}
export default Stepper;

const StepperDiv = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
`;

const StepperTitle = styled.div`
    font-size: 20px;
    margin-bottom: 8px;
`