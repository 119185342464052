import styled from "styled-components";

interface PriceCaptionParams {
    price: number,
    currency: string
    priceToCurrencySpace?: boolean
}
function PriceCaption ({price, currency, priceToCurrencySpace = false}: PriceCaptionParams) {
    return <PriceSpan>
        <span className={"finalValue"}>
            {price}
        </span>
        {priceToCurrencySpace && " "}
        <span className={"currency"}>
            {currency}
        </span>
    </PriceSpan>
}
export default PriceCaption;

let PriceSpan = styled.span`
    gap: 0px;
`