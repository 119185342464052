import { t } from "i18next";
import Stepper from "../../utils/Stepper";
import { UserOrder } from "../../../type/UserOrder";
import AseclaButton from "../../utils/AseclaButton";

type OrderRaisedParams = {
    msg: string;
    order?: UserOrder;
}
function OrderRaised({msg, order}: OrderRaisedParams) {
    return <>
        <Stepper stepNo={3} captions={[t("Define your needs"), t("Stepper_Verify"), t("Order")]}></Stepper>
        <p>{msg}</p>
        {order !== undefined && <p>{t("We gave this order the number", {orderName: order.orderId}) as string}</p>}
        <AseclaButton className="small" action={e => window.close()}>{t("Close the Window") as string}</AseclaButton>
    </>
}
export default OrderRaised;