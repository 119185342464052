import { useQuery } from "@tanstack/react-query";
import { OrganizationData } from "../type/responses/OrganizationData";
import OrganizationResponse, { handleOrganizationResponse } from "../type/responses/OrganizationResponse";
import { getUrl, prepareHeadersPromise } from "../store/ServerConnection";
import getAuthentication from "../store/AuthenticationPageBase";

export const TANSTACK_ORGANIZATIONS_USER_KEY = ["ORGANIZATIONS", "USER"];
interface useOrganizationsResult {
    organizations: OrganizationData[] | null,
    isFetching: boolean,
    isError: boolean,
    isAseclaAdmin: boolean,
    isFetched: boolean
}
export function useOrganizations(): useOrganizationsResult {
    const authentication = getAuthentication();

    let query = useQuery<OrganizationResponse>({
        queryKey: TANSTACK_ORGANIZATIONS_USER_KEY,
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            if (!authentication.initialized() || !authentication.isAuthenticated()) return null;
            let action = "getOrganizations";
            let url = getUrl(action);
            let head = await prepareHeadersPromise(null, authentication);
            const requestOptions = {
                method: 'POST',
                headers: head,
            };
            const response = await fetch(url, requestOptions);
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return response.json().then(r => handleOrganizationResponse(r) as any);
        }
    });

    return {
        organizations: query.data?.organizations ?? null,
        isAseclaAdmin: query.data?.aseclaAdmin ?? false,
        isError: query.isError,
        isFetching: query.isFetching,
        isFetched: query.isFetched,    
    }
}