import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import { UserOrder } from "../../../type/UserOrder";
import MultipleOrganizationsCheck from "../MultipleOrganizationsCheck";
import AseclaButton from "../../utils/AseclaButton";
import ConfirmOrderPage from "../../../page/ConfirmOrderPage";
import Loading from "../../utils/Loading";
import IsErrorMessage from "../../utils/IsErrorMessage";
import { useTargetOrder } from "../../../hooks/mutations/useTargetOrder";
import { useOrganizations } from "../../../hooks/useOrganizations";
import UserOrderVerifyAndConfirm from "./UserOrderVerifyAndConfirm";
import TitleBanner from "../../utils/TitleBanner";

function ConfirmOrderTarget() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t, i18n} = useTranslation();

    const { organizations, isFetching: loadingOrganizations, isError } = useOrganizations();
    const [ organizationConfirmed, setOrganizationConfirmed] = React.useState<boolean>(false);
    const [ searchParams, ] = useSearchParams();
    const [ order, setOrder ] = React.useState<UserOrder|null>(null);

    let orderParam: string|null = searchParams.get('order');
    let orderId: number = -1;
    if (orderParam !== undefined && orderParam !== null) {
        orderId = Number(orderParam);
    }
    const { takeControlOverOrder, errorOnTakingOver, tookOverOrder } = useTargetOrder(setOrder);

    let organizationsCanBeConfirmed = organizations != null && organizations.length === 1;
    React.useEffect(() => {
        if (organizationsCanBeConfirmed) {
            setOrganizationConfirmed(true);
        }
    }, [organizations]);

    React.useEffect(() => {
        if (props.authentication.initialized() 
                && orderParam != null 
                && !isNaN(orderId)
                && orderId != undefined
                && organizationConfirmed
                && props.getCurrentOrganization()?.haveManagerRights) {
            takeControlOverOrder({orderId, organizationConfirmed});
        }
    }, [props.authentication.initialized(), props.getCurrentOrganization(), organizationConfirmed]);


    const loginRequest = (register: boolean) => {
        let orderId: string|number|null = searchParams.get('order');
        if (orderId != null) {
            props.authentication.loginWithParams(ConfirmOrderPage.CONTEXT_PATH, register,
                [{
                    locale: i18n.language,
                    key: "order",
                    value: orderId
                }]
            );
        } else {
            props.authentication.login();
        }
    }

    if (errorOnTakingOver || isError) {
        return <IsErrorMessage></IsErrorMessage>
    }

    if ((props.authentication.initialized() && props.authentication.isAuthenticated() && organizations == null)
        || loadingOrganizations
        || (organizationsCanBeConfirmed && !organizationConfirmed && props.getCurrentOrganization()?.haveManagerRights)
        || (loadingOrganizations && order == null)
        || (!props.authentication.initialized())
        || (props.authentication.isAuthenticated() && organizationsCanBeConfirmed && order == null && props.getCurrentOrganization()?.haveManagerRights)) {
        return <Loading></Loading>
    }

    if (props.authentication.initialized() && !props.authentication.isAuthenticated() && order == null) {
        return <>{t("Please")} <button className="asecla-button small" onClick={(e) => loginRequest(false)}>{t("log in") as string}</button> {t("or")} <button className="asecla-button small" onClick={(e) => loginRequest(true)}>{t("register") as string}</button> {t("to request the order.")}</>
    }
    if (!organizationsCanBeConfirmed && !organizationConfirmed) {
        return <div>
            <TitleBanner title={t("Just before you buy")}></TitleBanner>
            <MultipleOrganizationsCheck
                    onOrganizationCreated={()=>setOrganizationConfirmed(true)}
                    noConfirmationNeeded={() => {if (!organizationConfirmed) {setOrganizationConfirmed(true)}}}
                    allowOrganizationCreation={true}
            >
                <AseclaButton action={(e) => setOrganizationConfirmed(true)}>{t("Proceed") as string}</AseclaButton>
            </MultipleOrganizationsCheck>
        </div>
    }

    if (!props.getCurrentOrganization()?.haveManagerRights) {
        return <>{t("You need to be manager in your organization to make an order.") as string}</>
    }

    if (!tookOverOrder) {
        return <Loading></Loading>
    }

    return <UserOrderVerifyAndConfirm
        orderStatus={order!.status}
        orderId={order!.orderId}
        length={order!.items[0].length}
        lengthUnit={order!.items[0].lengthUnit}
        licType={order!.items[0].licenseType}
        quantity={order!.items[0].noOfUsers}
        hideBonusCode={order!.hideBonusCode}
        bonusCodeToForce={order!.bonusCode}
        
    ></UserOrderVerifyAndConfirm>
}
export default ConfirmOrderTarget