import { useTranslation } from "react-i18next";

type UnitDivParams = {
    lengthUnit?: string ;
    lowercase?: boolean;
    no?: number;
}

function UnitDiv({no = 2, lengthUnit, lowercase} : UnitDivParams) {
    const {t} = useTranslation();

    if (lengthUnit === undefined) return <></>

    const applyCase = (txt: string): string => {
        if (lowercase) {
            return txt.toLowerCase();
        } else {
            return txt;
        }
    }
    
    return <>
    {lengthUnit === "Day" && <>{applyCase(t("Day" + (no == 1 ? "" : "s"))) as string}</>}
    {lengthUnit === "Month" && <>{applyCase(t("Month" + (no == 1 ? "" : "s"))) as string}</>}
    {lengthUnit === "Year" && <>{applyCase(t("Year" + (no == 1 ? "" : "s"))) as string}</>}
    </>
}

export default UnitDiv;