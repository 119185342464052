import { useMutation } from "@tanstack/react-query";
import { ContextProps } from "../../type/ContextProps";
import { Notifications } from "../../integration/Notifications";
import OrganizationResponse, { handleOrganizationResponse } from "../../type/responses/OrganizationResponse";
import { queryClient } from "../../App";
import { TANSTACK_ORGANIZATIONS_USER_KEY } from "../useOrganizations";
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { requestServer } from "../../store/ServerConnection";
import RenameOrganizationRequest from "../../type/request/RenameOrganizationRequest";
import ModifyInvoiceDataRequest from "../../type/request/ModifyInvoiceDataRequest";
import { OrganizationData } from "../../type/responses/OrganizationData";
import CreateOrganizationRequest from "../../type/request/CreateOrganizationRequest";
import { TANSTACK_ORGANIZATIONS_ADMIN_KEY } from "../queries/useAdminOrganizations";
import { t } from "i18next";
import { ValidationError } from "../../type/validation/ValidationError";

const success = (retData: OrganizationResponse, msg: string, setForcedOrganization?: (ret: OrganizationResponse) => void) => {
    const { serverSuccess } = Notifications();

    if (setForcedOrganization != undefined) {
        //The function should handle setQueryData call (it's using TANSTACK_ORGANIZATIONS_ADMIN_KEY in this case)
        //Forcing the returned data doesn't work, as the function returns organizations of current user (admin can see other organizations, so some organizations will not refresh)
        //setForcedOrganization!(retData);
        queryClient.invalidateQueries(TANSTACK_ORGANIZATIONS_ADMIN_KEY);
    } else {
        queryClient.invalidateQueries(TANSTACK_ORGANIZATIONS_USER_KEY);
        //Previous version: 
        //queryClient.setQueryData(TANSTACK_ORGANIZATIONS_USER_KEY, handleOrganizationResponse(retData));
        //but BE is returning only current organization (which messus up completely in case of multiple organizations)
    }
    serverSuccess(msg);
}

interface MutationParams {
    userName: string;
}

export function useAddUserToOrganization(organizationId?: number, setForcedOrganization?: (ret: OrganizationResponse) => void) {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    
    const { mutate, isSuccess, isLoading } = useMutation({
    mutationFn: ({userName}: MutationParams) => requestServer(
            "addUserToOrganization",
            {
                userName,
                organizationId: organizationId ?? props.getCurrentOrganization()?.id,
                action: "add",
                returnOnlyModifiedOrganization: setForcedOrganization == undefined
            },
            props
        ),
        onSuccess: (data: OrganizationResponse, {userName}: MutationParams, context: any) => success(data, t("User added", {userName: userName}), setForcedOrganization),
        onError: (error: any, {userName}: MutationParams) => serverError(t("Error while adding {{userName}} to organization", {userName: userName}))
    });

    return { mutate, isSuccess, isLoading };
}

export function useRemoveUserFromOrganization(organizationId?: number, setForcedOrganization?: (ret: OrganizationResponse) => void) {
  const { serverError } = Notifications();
  const props = React.useContext(AseclaDataContext) as ContextProps;
  
  const { mutate, isSuccess, isLoading } = useMutation({
    mutationFn: ({userName}: MutationParams) => requestServer(
          "removeUserFromOrganization",
          {
              userName: userName,
              organizationId: organizationId ?? props.getCurrentOrganization()?.id,
              action: "remove",
              returnOnlyModifiedOrganization: setForcedOrganization == undefined
          },
          props
        ),
    onSuccess: (data: OrganizationResponse, {userName}: MutationParams) => success(data, t("User removed", {userName: userName}), setForcedOrganization),
    onError: (error: any, {userName}: MutationParams) => serverError(t("Error while removing {{userName}} from organization", {userName: userName}))
  });

  return { mutate, isSuccess, isLoading };
}

export function useSetUserLicense(organizationId?: number, setForcedOrganization?: (ret: OrganizationResponse) => void) {
  const { serverError } = Notifications();
  const props = React.useContext(AseclaDataContext) as ContextProps;

  const { mutate, isSuccess, isLoading } = useMutation({
    mutationFn: ({userName, licenseId, turnOn}: any) => {
      let req = {
        targetUser: userName,
        licenseId: licenseId,
        turnOn: turnOn,
        organizationId: organizationId ?? props.getCurrentOrganization()?.id,
        returnOnlyModifiedOrganization: setForcedOrganization == undefined
      }
      return requestServer("setUserLicense", req, props);
    },
    onSuccess: (data: OrganizationResponse, variables: any) => success(data, t("Change done"), setForcedOrganization),
    onError: (error: any) => serverError(t("Error while modifying organization"))
  });

  return { mutate, isSuccess, isLoading };
}

export function useSetRole(organizationId?: number, setForcedOrganization?: (ret: OrganizationResponse) => void) {
  const { serverError } = Notifications();
  const props = React.useContext(AseclaDataContext) as ContextProps;

  const { mutate, isSuccess, isLoading } = useMutation({
    mutationFn: ({roleName, usrToChange, setRoleOn}: any) => {
      let req = {
        roleType: roleName,
        usrToChange: usrToChange,
        setRoleOn: setRoleOn,
        organizationId: organizationId ?? props.getCurrentOrganization()?.id,
        returnOnlyModifiedOrganization: setForcedOrganization == undefined
      }
      return requestServer("setRole", req, props);
    },
    onSuccess: (data: OrganizationResponse, variables: any) => success(data, t("Change done"), setForcedOrganization),
    onError: (error: any) => serverError(t("Error while setting role"))
  });

  return { mutate, isSuccess, isLoading };
}


export function useRenameOrganization() {
    const { serverError } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;

    const { mutate: renameOrganization } = useMutation({
      mutationFn: (req: RenameOrganizationRequest) => {
          return requestServer("renameOrganization", req, props);
      },
      onSuccess: (ret: OrganizationResponse) => {
        queryClient.invalidateQueries({queryKey: TANSTACK_ORGANIZATIONS_USER_KEY});
        queryClient.setQueryData(TANSTACK_ORGANIZATIONS_USER_KEY, handleOrganizationResponse(ret));
      },
      onError: () => serverError(t("Failed to rename organization"))
    });

    return { renameOrganization };
}

export function useModifyInvoiceData(admin: boolean, setOrganizationToEditInvoiceData: (val: OrganizationData | null) => void) {
  const { serverError, serverSuccess } = Notifications();
  const props = React.useContext(AseclaDataContext) as ContextProps;
  const [validationError, setValidationError] = React.useState<ValidationError|null>(null);

  const { mutate: modifyInvoiceData } = useMutation({
    mutationFn: (req: ModifyInvoiceDataRequest) => {
        setValidationError(null);
        return requestServer("modifyInvoiceData", req, props);
    },
    onSuccess: (ret: OrganizationResponse|ValidationError, {organizationId, invoiceData}: ModifyInvoiceDataRequest) => {
        if (!ret.success) {
            setValidationError(ret as ValidationError);
        } else {
            queryClient.invalidateQueries({queryKey: TANSTACK_ORGANIZATIONS_USER_KEY});
            queryClient.setQueryData(TANSTACK_ORGANIZATIONS_USER_KEY, handleOrganizationResponse(ret as OrganizationResponse));
            if (admin) {
                queryClient.invalidateQueries({queryKey: TANSTACK_ORGANIZATIONS_ADMIN_KEY});
            }
            setOrganizationToEditInvoiceData(null);
            serverSuccess(t("Invoice data updated"));
        }
    },
    onError: (error: any) => serverError(t("Failed to modify invoice data"))
  });

  return { modifyInvoiceData, validationError, clearValidationError: ()=>setValidationError(null) };
}

export function useCreateOrganization(onOrganizationCreated?: () => void) {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    const [validationError, setValidationError] = React.useState<ValidationError|null>(null);

    const { mutate: createOrganization } = useMutation({
        mutationFn: (req: CreateOrganizationRequest) => {
            setValidationError(null);
            return requestServer("createOrganization", req, props);
        },
        onSuccess: (ret: OrganizationResponse|ValidationError, req: CreateOrganizationRequest) => {
            if (!ret.success) {
                setValidationError(ret as ValidationError);
            } else {
                queryClient.invalidateQueries({queryKey: TANSTACK_ORGANIZATIONS_ADMIN_KEY});
                queryClient.setQueryData(TANSTACK_ORGANIZATIONS_USER_KEY, handleOrganizationResponse(ret as OrganizationResponse));
                if (onOrganizationCreated != null) {
                    onOrganizationCreated();
                }
                serverSuccess(t("Created organization '{{orgName}}'", {orgName: req.organizationName}));
            }
        },
        onError: (error: any) => serverError(t("Failed to create organization"))
    });

   return { createOrganization, validationError };
}

export function useDeleteOrganization() {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;

    const { mutate: deleteOrganization } = useMutation({
        mutationFn: ({organizationId}: any) => {
            return requestServer("deleteOrganization", {organizationId}, props);
        },
        onSuccess: (ret: OrganizationResponse) => {
            queryClient.invalidateQueries({queryKey: TANSTACK_ORGANIZATIONS_ADMIN_KEY});
            serverSuccess(t("Organization deleted"));
        },
        onError: () => serverError(t("Failed to delete organization"))
   });

  return { deleteOrganization };
}

export function useChangeOrganizationDiscount() {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;

    const { mutate: changeOrganizationDiscount } = useMutation({
        mutationFn: ({organizationId, newDiscount}: any) => {
            return requestServer("changeOrganizationDiscount", {organizationId, newDiscount}, props);
        },
        onSuccess: (ret: OrganizationResponse, {organizations, organizationId, newDiscount, orgsCount}: any) => {
            let save: OrganizationData[] = [];
            for (let i = 0; i < organizations.length; i++) {
                save[i] = {...organizations[i]};
                if (save[i].id === organizationId) {
                    save[i].discount = newDiscount;
                }
            }
            updateAdminOrganizations(save, orgsCount);
            serverSuccess(t("Discount changed to ") + newDiscount);
        },
        onError: () => serverError(t("Failed to save discount"))
    });

    return { changeOrganizationDiscount };
}

function updateAdminOrganizations(orgsToSave: OrganizationData[], orgsCount?: number) {
    let len = orgsToSave.length;
    queryClient.invalidateQueries({queryKey: TANSTACK_ORGANIZATIONS_ADMIN_KEY});
    queryClient.setQueryData(TANSTACK_ORGANIZATIONS_ADMIN_KEY, {
        organizations: orgsToSave,
        count: orgsToSave ?? len
    })
}