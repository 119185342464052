import './Pagination.css'
import { useTranslation } from "react-i18next";

export type PaginationParams = {
  paginationData: any,
  canPreviousPage: boolean,
  canNextPage: boolean,
  gotoPage: (pageNumber: number) => void,
  nextPage: () => void,
  previousPage: () => void,
  pageSize: number,
  setPageSize: (pageSize: number) => void,
  currentPageNo: number,
  setCurrentPageNo: (pageNo: number) => void,
  noOfPages: number|null
  showPageSize?: boolean,
  showPagination?: boolean,
}

function Pagination({
    paginationData,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    currentPageNo,
    setCurrentPageNo,
    pageSize,
    noOfPages,
    showPageSize,
    showPagination,
}: PaginationParams){
  const {t} = useTranslation();
  paginationData.getPageSize = () => {return pageSize}
  paginationData.getPageIndex = () => currentPageNo;

  let nextPageAction = () => {
    setCurrentPageNo(currentPageNo + 1);
    nextPage();
  }
  let previousPageAction = () => {
    setCurrentPageNo(currentPageNo - 1);
    previousPage();
  }

  let gotoPageAction = (no: number) => {
    setCurrentPageNo(no);
    gotoPage(no);
  }         

  let pageCountCaption = null;
  if (noOfPages != null) {
    pageCountCaption = <>of {noOfPages}</>;
  }

  return <>{(showPagination??true) && <span className="pagination">
    <button className="asecla-button small" onClick={() => gotoPageAction(0)} disabled={!canPreviousPage}>
      {'<<'}
    </button>
    <button className="asecla-button small" onClick={() => previousPageAction()} disabled={!canPreviousPage}>
      {'<'}
    </button>
    <span>
      <strong>
        {' '}
        <input
            type="number"
            onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPageAction(page)
            }}
            value={currentPageNo + 1}
            style={{ width: '45px' }}
        />
        {pageCountCaption}
        {' '}
      </strong>
    </span>
    <button className="asecla-button small" onClick={() => nextPageAction()} disabled={!canNextPage}>
      {'>'}
    </button>
    {noOfPages != null &&
        <button className="asecla-button small" onClick={() => gotoPageAction(noOfPages - 1)} disabled={!canNextPage}>
        {'>>'}
        </button>
    }{' '}
    <select
      className="small" 
      value={pageSize}
      onChange={e => {
        setPageSize(Number(e.target.value))
      }}
    >
      {(showPageSize??true) && [10, 50, 100, 1000].map(pageSize => (
        <option key={pageSize} value={pageSize}>
          {t("Show") as string} {pageSize}
        </option>
      ))}
    </select>
  </span>}</>
}

export default Pagination;