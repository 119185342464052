import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import OrganizationSwitchDropdown from "../header/OrganizationSwitchDropdown";
import { useTranslation } from 'react-i18next';
import CreateOrganization from "../CreateOrganization";
import { useOrganizations } from "../../hooks/useOrganizations";
import Loading from "../utils/Loading";
import IsManager from "../checks/IsManager";
import styled from "styled-components";

interface MultipleOrganizationsCheck {
    children: JSX.Element;
    noConfirmationNeeded?: () => void,
    onCancel?: () => void,
    onOrganizationCreated?: () => void, 
    checkCurrentOrganization?: boolean,
    allowOrganizationCreation: boolean
}

function MultipleOrganizationsCheck({children, onCancel, noConfirmationNeeded, onOrganizationCreated, allowOrganizationCreation, checkCurrentOrganization = true}: MultipleOrganizationsCheck) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t} = useTranslation();

    const { organizations, isFetching } = useOrganizations();

    React.useEffect(() => {
        if (noConfirmationNeeded !== undefined && organizations != null && organizations.length === 1) {
            noConfirmationNeeded();
        }
    }, [organizations])

    if (isFetching || organizations == null) {
        return <Loading></Loading>
    }

    return <>{organizations != null && organizations.length > 1 && <div>
            {t("You are assigned to multiple organizations. Please double check you wanted to buy as") as string}:&nbsp;
             <OrganizationSwitchDropdown enableCreation={false}></OrganizationSwitchDropdown>
        </div>}
        <div>
            {(!props.authentication.isAuthenticated() || organizations != null && organizations.length > 0) && <IsManager force={!checkCurrentOrganization} msg={t("You need to be manager in your organization to make an order.")}>
                {children}
            </IsManager>}
            {organizations != null && organizations.length === 0 && allowOrganizationCreation && <div>
                <BottomMarginDiv>{t("You are not assigned to any organization. Create one or ask admin to be added.") as string}</BottomMarginDiv>
                <CreateOrganization onOrganizationCreated={onOrganizationCreated}></CreateOrganization>
            </div>}
            {onCancel != undefined && <button className="asecla-button small" onClick={(e) => onCancel()}>{t("Cancel") as string}</button>}
        </div>
    </>
}
export default MultipleOrganizationsCheck;

let BottomMarginDiv = styled.div`
    margin-bottom: 16px;
`