import { t } from "i18next";
import { LicenseType } from "../../../type/LicenseType";
import { PropertyQuestion } from "../PropertyQuestion";
import React from "react";
import styled from "styled-components";
import i18n from "../../../i18n";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import MultipleOrganizationsCheck from "../MultipleOrganizationsCheck";
import AseclaProductsColumn from "./AseclaProductColumn";
import Stepper from "../../utils/Stepper";
import "./UserOrderWithMenu.css";
import TitleBanner from "../../utils/TitleBanner";
import UserOrderMainTable from "./UserOrderMainTable";
import IsManager from "../../checks/IsManager";

interface UserOrderDefineProps {
    setQuantity: (quantity: number) => void,
    setLength: (length: number) => void,
    quantity: number | undefined,
    length: number,
    licType: LicenseType,
    licTypePropValue: Array<PropertyQuestion>,
    setLicTypePropValue: React.Dispatch<React.SetStateAction<Array<PropertyQuestion>>>,
  
    setPropertiesAccepted: React.Dispatch<React.SetStateAction<boolean>>,
    allAvailableLicenses: LicenseType[],
    switchSelectedLicenseTypeTo: (licenseTypeNo : number) => void ,
}
function UserOrderDefine({setQuantity, setLength, length, quantity, licType, licTypePropValue, setLicTypePropValue,
            setPropertiesAccepted, allAvailableLicenses, switchSelectedLicenseTypeTo}: UserOrderDefineProps) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const accept = (noOfYears: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLength(noOfYears);
        setPropertiesAccepted(true);
    }

    return <>
        <TitleBanner title={t("Select product")}></TitleBanner>
        <Stepper stepNo={1} captions={[t("Define your needs"), t("Stepper_Verify"), t("Order")]}></Stepper>
        <div className="OfferContainer">
            <div className="OfferMenu">
                <LeftMenu switchSelectedLicenseTypeTo={switchSelectedLicenseTypeTo} allAvailableLicenses={allAvailableLicenses} licType={licType}></LeftMenu>
            </div>
            <div className="OfferContent">
                <div className="menu-placeholder">
                    <LeftMenu switchSelectedLicenseTypeTo={switchSelectedLicenseTypeTo} allAvailableLicenses={allAvailableLicenses} licType={licType}></LeftMenu>
                </div>
                <UserOrderMainTable quantity={quantity} licType={licType} setQuantity={setQuantity} accept={accept}></UserOrderMainTable>
                <IsManager noOrg={true}><>
                    <p>{t("Produkt dostarczany w wersji cyfrowej w postaci dostępu do zakupionych funkcjonalności z konta ASECLA.") as string}</p>
                    <p>{t("If you have bonuscode, you can apply it in final order step") as string}</p>
                </></IsManager>
                <Paragraph>
                    {props.authentication.isAuthenticated() &&
                        <MultipleOrganizationsCheck checkCurrentOrganization={false} allowOrganizationCreation={false}><></></MultipleOrganizationsCheck>
                    }
                </Paragraph>
                <IsManager noOrg={true}>
                    <Paragraph>{t("subscription_aspect") as string}</Paragraph>
                </IsManager>
            </div>
        </div>
    </>;
}

type LeftMenuParams = {
    allAvailableLicenses: LicenseType[],
    switchSelectedLicenseTypeTo: (licenseTypeNo : number) => void ,
    licType: LicenseType;
}
function LeftMenu({switchSelectedLicenseTypeTo, licType, allAvailableLicenses}: LeftMenuParams) {
    if (allAvailableLicenses === undefined) {
        return <></>
    }
    let displayValues: LicenseType[] = [...allAvailableLicenses];
    
    let order = (licType?: LicenseType): number => {
        if (licType == undefined) return -1;
        let name = licType.names[i18n.language];
        if (name === 'Silver') return 0;
        if (name === 'Gold') return 1;
        if (name === 'Platinum') return 2;
        if (name === 'Hello') return 3;
        return -1;
    }

    return <>
        <div className="OneLine">
            <ProductSelect className="small" onChange={(e:React.ChangeEvent<HTMLSelectElement>) => {switchSelectedLicenseTypeTo(Number.parseInt(e.target.value))}} value={licType !== undefined ? licType.id : undefined}>
                        {displayValues.sort((l1, l2) => (order(l1) - order(l2))).map((lic:LicenseType) => 
                            <option key={lic.id} value={lic.id}>{lic.names[i18n.language]}</option>
                        )}
                    </ProductSelect>
        </div>
        {licType && <AseclaProductsColumn columnName={licType.names[i18n.language]} licType={licType} stepNo={1}></AseclaProductsColumn>}
    </>
}
export default UserOrderDefine;

const Paragraph = styled.div`
    margin-bottom: 12px;
    margin-right: 30px;
`;
const ProductSelect = styled.select`
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
    font-size: 20px;
`;
