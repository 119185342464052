import { useTranslation } from "react-i18next";
import { ContextProps } from "../../type/ContextProps";
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";

export const PAYMENT_METHOD_BANK_TRANSFER: string = "bankTransfer";
interface PaymentMethodParams {
    selectedPaymentMethod: string,
    setSelectedPaymentMethod: (newMethod: string) => void
}
function PaymentMethod({selectedPaymentMethod, setSelectedPaymentMethod}: PaymentMethodParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t} = useTranslation();
    return <div>
        <h4 className="asecla-heading">{t("Payment method") as string}</h4>
        <div className="configParent">
            <div className="configurationContainer">
                <input
                    type="radio"
                    value={PAYMENT_METHOD_BANK_TRANSFER}
                    checked={selectedPaymentMethod === PAYMENT_METHOD_BANK_TRANSFER}
                    onChange={e => setSelectedPaymentMethod(e.target.value)}
                    id="paymentBankTransfer"
                    name="paymentBankTransfer"
                    disabled={props.freezeHeaderCounter > 0}
                />
                <label htmlFor="paymentBankTransfer">{t("Bank Transfer") as string}</label>
            </div>
        </div>
    </div>
}

export default PaymentMethod;