import './Table.css';

export type CellProp = {
    colSpan: number
}

type TableParams = {
    headers: any[],
    cellProps?: CellProp[][],
    tableContent: any[][],
    rowClass?: string[]
}

function Table(params: TableParams) {

    const getColSpan = (rowNo: number, colNo: number): number|undefined => {
        if (params.cellProps == null) return undefined;
        let row: CellProp| undefined = params.cellProps[rowNo][colNo];
        if (row == undefined) return undefined;
        return row.colSpan;
    }

    return <table className="asecla-table">
        <thead>
            <tr>
                {params.headers.map((h: any, colNo: number) => <th key={colNo}>{h}</th>)}
            </tr>
        </thead>
        <tbody>
            {params.tableContent.map((row: any[], rowNo: number) => <tr key={"row" + rowNo} className={(params.rowClass ?? [])[rowNo] ?? ""}>
                {row.map((cell: any, colNo: number) => <td colSpan={getColSpan(rowNo, colNo)} key={"cell_" + rowNo + "_" + colNo}>
                    {cell}
                </td>)}
            </tr>)}
        </tbody>
    </table>
}

export default Table;