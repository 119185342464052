import React, { ChangeEvent } from 'react';
import AseclaDataContext from '../../store/AseclaDataContext';
import { ContextProps } from '../../type/ContextProps';
import { useTranslation } from 'react-i18next';
import ManageOrganizationsPage from '../../page/ManageOrganizationsPage';
import { OrganizationData } from '../../type/responses/OrganizationData';
import { useOrganizations } from '../../hooks/useOrganizations';
import { useNavigate } from 'react-router-dom';

interface OrganizationSwitchDropdownParams {
    enableCreation: boolean,
}
function OrganizationSwitchDropdown({enableCreation} : OrganizationSwitchDropdownParams) {
    const MANAGE_VALUE : string = "###Manage your organizations...";
    let props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const {t} = useTranslation();
    const navigate = useNavigate();

    const disableHeader = (): boolean => {
        return props.freezeHeaderCounter > 0 || props.disableOrganizationSwitch;
    }
    const {organizations, isFetching} = useOrganizations();

    let orgNo = props.currentOrganizationNo;
    if (orgNo === -1 && organizations !== null && organizations.length > 0) {
        orgNo = 0;
    }
    let curOrg = (organizations == null || orgNo == -1) ? null : organizations[orgNo];

    const onOrganizationChange = (e : ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value === MANAGE_VALUE) {
            navigate("/" + ManageOrganizationsPage.CONTEXT_PATH);
        } else {
            if (organizations != null) {
                for (let i = 0; i < organizations.length; i++) {
                    if (Number(e.target.value) === organizations[i].id) {
                        props.setCurrentOrganizationNo(i);
                    }
                }
            }
        }
    }

    if (curOrg == null || isFetching) {
        return <></>
    }

    return <select className="small" value={curOrg.id} onChange={onOrganizationChange} disabled={disableHeader()}>
                {enableCreation && <optgroup label={t("Your organizations")}>
                                        {organizations!.map((h : OrganizationData)=> <option key={h.id} value={h.id}>{h.name}</option>)}
                                    </optgroup>}
                {!enableCreation && organizations!.map((h : OrganizationData)=> <option key={h.id} value={h.id}>{h.name}</option>)}               
                {enableCreation && <option value={MANAGE_VALUE}>{t("Manage organizations") as string}</option>}
            </select>
}

export default OrganizationSwitchDropdown;