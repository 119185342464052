import React from "react";
import { OrganizationData } from "../../type/responses/OrganizationData";
import { useTranslation } from 'react-i18next';
import AseclaButton from "../utils/AseclaButton";
import { InvoiceDataFormOnInvoiceData } from "../InvoiceDataForm";
import InvoiceData from "../../type/InvoiceData";
import ModifyInvoiceDataRequest from "../../type/request/ModifyInvoiceDataRequest";
import Loading from "../utils/Loading";
import { useModifyInvoiceData, useRenameOrganization } from "../../hooks/mutations/useOrganizationOperations";
import styled from "styled-components";
import TitleBanner from "../utils/TitleBanner";

type ManageOrganizationDataParams = {
    organization: OrganizationData| null
    invoiceData: InvoiceData| null
    setInvoiceData: React.Dispatch<React.SetStateAction<InvoiceData | null>>
    onOrganizationSaved: ((val: OrganizationData | null) => void) | null
}
function ManageOrganizationData({organization, invoiceData, setInvoiceData, onOrganizationSaved}: ManageOrganizationDataParams) {
    const {t} = useTranslation();
    const { modifyInvoiceData, validationError, clearValidationError } = useModifyInvoiceData(false, onOrganizationSaved == null ? () => {} : onOrganizationSaved);

    const save = () => {
        let req: ModifyInvoiceDataRequest = {
            organizationId: organization?.id!,
            invoiceData: invoiceData!
        }
        modifyInvoiceData(req);
    }

    if (organization == null || invoiceData == null) return <Loading></Loading>

    return <>
        <TitleBanner title={t("Manage 1 organization") + " " + organization.name}></TitleBanner>
        <InvoiceDataFormOnInvoiceData
            invoiceData={invoiceData}
            setInvoiceData={setInvoiceData}
            validationError={validationError}
        ></InvoiceDataFormOnInvoiceData>
        <ActionButtonsDiv>
            {onOrganizationSaved !== null && <>
                <AseclaButton action={save}>{t("Save Data and back to organizations") as string}</AseclaButton>
                <AseclaButton action={(e: any) => {clearValidationError(), onOrganizationSaved(null)}}>{t("Cancel Edititng Invoice Data") as string}</AseclaButton>
            </>}
            {onOrganizationSaved === null && <>
                <AseclaButton action={save}>{t("Save") as string}</AseclaButton>
            </>}
        </ActionButtonsDiv>
    </>
}
export default ManageOrganizationData;

const ActionButtonsDiv = styled.div`
    button {
        margin-left: 5px;
        margin-right: 5px
    }
    margin-top: 20px;
    margin-bottom: 50px;
`;