import { UserOrder, handleUserOrderDates } from "./UserOrder"

export type UserOrderHistory = UserOrder & { 
    comment: string
    changeDate: Date
    actionOwner: string
}

export const handleUserOrderHistoryDates = (order: UserOrderHistory): UserOrderHistory => {
    var t: any = order.changeDate;
    order.changeDate = new Date(t * 1000);
    return handleUserOrderDates(order) as UserOrderHistory;
}