import { ContextProps } from "../type/ContextProps";

let lang = "en";
/**
 * This is ASECLA-PageBase integration layer. It is used to integrate with all the common ASECLA 
 * functionality that includes:
 * - logging
 * - header layout
 * - localization
 * - ...
 */
export interface PageBaseIntegration {
    setContext(context : ContextProps): void;
    setup(): void;
    getUserEmail(): string;
    getUserDisplayName(): string;
    requireLogin(): void;
    login(): void;
    logout(): void;
    authenticated(): boolean;
    getLangCode(): string;
    setLanguage(lang: string): void
}
export const pageBaseIntegration = (): PageBaseIntegration => {
    let props: ContextProps;

    const setContext = (context : ContextProps) => {
        props = context;
    }

    const getConfig = (): any => {
        return {
                url: process.env.REACT_APP_KEYCLOAK_URL,
                realm: process.env.REACT_APP_KEYCLOAK_REALM,
                clientId: process.env.REACT_APP_CLIENT
            };
    }

    const getAsecla = () => {
        let asecla = (window as any).ASECLA;
        if (asecla === undefined) {
            asecla = (window as any).ASECLA = Object.create(null);
        }
        return asecla;
    }
    let asecla = getAsecla();

    /**
     * Prepare login configuration
     */
    const setup = (): void => {
        asecla.config = asecla.config || Object.create(null);
        asecla.config.keycloakConfiguration = asecla.config.keycloakConfiguration || getConfig();
        asecla.config.loginCallback = asecla.config.loginCallback || [];
    }

    const getUserEmail = (): string => {
        return asecla.Auth.getUserEmail();
    }

    const getUserDisplayName = (): string => {
        if (asecla.Auth == undefined) return "";
        return asecla.Auth.getUserDisplayName();
    }

    const requireLogin = (): void => {
        return asecla.Auth.requireLogin();
    }

    const login = (): void => {
        return asecla.Auth.login();
    }
    const logout = (): void => {
        return asecla.Auth.logout();
    }
    const getLangCode = (): string => {
        if (asecla.Commons === undefined || asecla.Commons.Locale === undefined) return lang;
        return asecla.Commons.Locale.getCurrent().code || lang;
    }
    const setLanguage = (l: string) => {
        lang = l;
    }
    
    const authenticated = (): boolean => {
        let keycloak = (window as any).keycloak;
        if (keycloak === undefined) return false;
        return keycloak.authenticated;
    }

    return {
        setContext,
        setup,
        getUserEmail,
        getUserDisplayName,    
        requireLogin,
        login,
        logout,
        authenticated,
        getLangCode,
        setLanguage
    }
}