import { Commons } from "./Commons";

interface NotificationRes {
    success: (text: string, ttl: number) => void
    error: (text: string, ttl: number) => void
    warning: (text: string, ttl: number) => void
    serverError: (text: string) => void
    serverSuccess: (text: string) => void
    warnValidation: (text: string) => void
}
export function Notifications(): NotificationRes {

    const { getCommons, getHeaderInstance } = Commons();

    const msg = (msgType: ASECLA.Commons.NotificationType, text: string, ttl: number) => {
        let commons = getCommons();
        let header = getHeaderInstance();
        if (commons != undefined && header != undefined) {
            header.notificationBar.add(new commons.Notification(msgType, text, { ttl: ttl, closeable: true }));
        }
    }

    const success = (text: string, ttl: number) => msg("success", text, ttl);
    const warning = (text: string, ttl: number) => msg("warning", text, ttl);
    const error = (text: string, ttl: number) => msg("error", text, ttl);

    return {
        success, error, warning,
        serverError: (text) => error(text, 30),
        serverSuccess: (text) => success(text, 30),
        warnValidation: (text) => warning(text, 10),
    }
}