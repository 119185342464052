import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";

interface IsManagerParams {
    children: JSX.Element,
    force?: boolean,
    invert?: boolean,
    msg?: string
    noOrg?: boolean
}
function IsManager({children, invert = false, force = false, msg, noOrg = false}: IsManagerParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const isManager: boolean = 
        invert !== (props.getCurrentOrganization() != null && (props.getCurrentOrganization()?.haveManagerRights ?? false))
        || (invert !== (noOrg && props.getCurrentOrganization() === null));
        

    return <>
        {(force || isManager) && children}
        {(!isManager) && <p><b><big>{msg}</big></b></p>}
    </>
}

export default IsManager;