import React from "react";
import ConstantValuesBigTableFactory from "../../utils/bigTable/ConstantValuesBigTableFactory";
import { DataSource } from "../../utils/bigTable/definition/DataSource";
import { ContextProps } from "../../../type/ContextProps";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { useUserOrderHistory } from "../../../hooks/useUserOrders";
import { HeaderDefinition } from "../../utils/bigTable/definition/HeaderDefinition";
import { HISTORY_ACTION_OWNER, HISTORY_CHANGE_DATE, HISTORY_COMMENT, HISTORY_STATUS, OrderHistoryColumns } from "./OrderHistoryColumns";

function OrderHistory({aseclaAdminModule, userOrder}: any) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const userOrderHistory = useUserOrderHistory([userOrder], props.getCurrentOrganization()?.id!)[0];
    const [tableHeaders, ] = React.useState<HeaderDefinition[]>(OrderHistoryColumns(aseclaAdminModule,
        aseclaAdminModule
            ? [HISTORY_ACTION_OWNER, HISTORY_CHANGE_DATE, HISTORY_COMMENT, HISTORY_STATUS]
            : [HISTORY_CHANGE_DATE, HISTORY_COMMENT, HISTORY_STATUS]
    ));

    React.useEffect(() => {
        userOrderHistory.refetch();
    }, [])

    let data: DataSource = {
        rowData: [userOrderHistory],
    }

    return <ConstantValuesBigTableFactory
        dataSource={data}
        allHeaders={tableHeaders}
        objects={userOrderHistory.history}
        initPageSize={10}
    ></ConstantValuesBigTableFactory>
}

export default OrderHistory;