import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getUrl, prepareHeadersPromise } from "../../store/ServerConnection";
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { InformUserMailResponse } from "../../type/responses/InformUserMailResponse";

export const INFORM_USER_MAIL_KEY = "INFORM_USER_MAIL";

export function useInformUserMail(lang: string): UseQueryResult<InformUserMailResponse|null, unknown> {
    const props = React.useContext(AseclaDataContext);

    return useQuery<InformUserMailResponse|null>({
        queryKey: [INFORM_USER_MAIL_KEY, lang],
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            let currOrg = (props.getCurrentOrganization == undefined ? undefined : props.getCurrentOrganization());
            if (currOrg == undefined || !props.authentication!.initialized() || !props.authentication!.isAuthenticated()) return null;
            const requestOptions = {
                method: 'GET',
                headers: await prepareHeadersPromise(null, props.authentication),
            };
            let response = await fetch(getUrl("getInformUserMail?lang=" + lang), requestOptions);
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return ((await response.json()) as InformUserMailResponse);
        }
    });
}
