import React, { MouseEvent, ReactNode } from 'react';
import AseclaDataContext from '../../store/AseclaDataContext';
import { ContextProps } from '../../type/ContextProps';
import './Table.css';
import './AseclaButton.css';

type AseclaButtonParams = {
    children: ReactNode,
    action: (e: MouseEvent<HTMLButtonElement>) => void,
    enabled?: boolean
    disableGUI?: boolean
    id?: string
    className?: string,
}
function AseclaButton({children, action, disableGUI = false, enabled = true, id, className = ""}: AseclaButtonParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const freezePage = (): boolean => {
        return props.freezeHeaderCounter > 0;
    }

    const onClickAction = (e: MouseEvent<HTMLButtonElement>) => {
        if (disableGUI) {
            props.freezeHeader(true);
        }
        action(e);
    }

    return <button id={id} className={'asecla-button ' + className} onClick={onClickAction} disabled={freezePage() || enabled === false}>{children}</button>
}

export default AseclaButton;
