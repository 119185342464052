import styled from "styled-components";
import Price from "../pricing/Price";
import AseclaButton from "../../utils/AseclaButton";
import { LicenseType } from "../../../type/LicenseType";
import { t } from "i18next";
import { ContextProps } from "../../../type/ContextProps";
import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { Trans } from "react-i18next";
import UnitDiv from "../../utils/UnitDiv";

type UserOrderMainTableParams = {
    licType: LicenseType,
    quantity: number|undefined,
    accept: (noOfYears: number) => (e:  React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    setQuantity: (quantity: number) => void
}
function UserOrderMainTable({licType, quantity, accept, setQuantity}: UserOrderMainTableParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const canSelect: boolean = props.getCurrentOrganization() == null || (props.getCurrentOrganization()?.haveManagerRights ?? false);


    const unit = licType.lengthUnit;

    return <OfferTable>
        <thead>
            <tr><th>{t("Number of Licenses") as string}</th><th>{t("Interval") as string}</th><th>{t("Gross value for protection") as string}</th></tr>
        </thead>
        <tbody>
            {licType.technicalName !== "Hello" && <>
                <tr>
                    <td rowSpan={3}>
                        <input
                            max={licType.maxQuantity === 0 ? undefined : licType.maxQuantity}
                            value={quantity}
                            min={licType.minQuantity === 0 ? 1 : licType.minQuantity}
                            onChange={e => {if (!isNaN(Number(e.target.value))) setQuantity(Number(e.target.value))}}
                            id="quantity"
                            name="quantity"
                            type="number"
                            size={5}
                            />
                    </td>
                    <td><Trans i18nKey="protection_length" components={{ unit: <UnitDiv no={1} lengthUnit={unit} lowercase={false}></UnitDiv>}} values={{ length: 1}}></Trans></td>
                    <td><Price length={1} licenseType={licType!} quantity={quantity!} showDiscount={true} showHint={false}></Price></td>
                    <td><AseclaButton className="small" action={accept(1)} enabled={canSelect}>{t("Select") as string}</AseclaButton></td>
                </tr>
                <tr>
                    <td><Trans i18nKey="protection_length" components={{ unit: <UnitDiv no={2} lengthUnit={unit} lowercase={false}></UnitDiv>}} values={{ length: 2}}></Trans></td>
                    <td><Price length={2} licenseType={licType!} quantity={quantity!} showDiscount={true} showHint={false}></Price></td>
                    <td><AseclaButton className="small" action={accept(2)} enabled={canSelect}>{t("Select") as string}</AseclaButton></td>
                </tr>
                <tr>
                    <td><Trans i18nKey="protection_length" components={{ unit: <UnitDiv no={3} lengthUnit={unit} lowercase={false}></UnitDiv>}} values={{ length: 3}}></Trans></td>
                    <td><Price length={3} licenseType={licType!} quantity={quantity!} showDiscount={true} showHint={false}></Price></td>
                    <td><AseclaButton className="small" action={accept(3)} enabled={canSelect}>{t("Select") as string}</AseclaButton></td>
                </tr>
            </>}
            {licType.technicalName === "Hello" && <>
                <tr>
                    <td rowSpan={3}>
                        <input
                            max={licType.maxQuantity === 0 ? undefined : licType.maxQuantity}
                            value={quantity}
                            min={licType.minQuantity === 0 ? 1 : licType.minQuantity}
                            onChange={e => {if (!isNaN(Number(e.target.value))) setQuantity(Number(e.target.value))}}
                            id="quantity"
                            name="quantity"
                            type="number"
                            size={5}
                            />
                    </td>
                    <td><Trans i18nKey="protection_length" components={{ unit: <UnitDiv no={7} lengthUnit={unit} lowercase={false}></UnitDiv>}} values={{ length: 7}}></Trans></td>
                    <td><Price length={1} licenseType={licType!} quantity={quantity!} showDiscount={true} showHint={false}></Price></td>
                    <td><AseclaButton className="small" action={accept(1)} enabled={canSelect}>{t("Select") as string}</AseclaButton></td>
                </tr>
            </>}
        </tbody>
    </OfferTable>
}
export default UserOrderMainTable;

const OfferTable = styled.table`
    width: calc(100% - 20px);
    text-align: center;
`;

