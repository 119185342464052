
type LicenseLimitationParams = {
    id: string,
    value: number,
    setValue(value: number): void,
    label: string,
    hideSwitch?: boolean
}

function LicenseLimitation({id, value, setValue, label, hideSwitch} : LicenseLimitationParams) {
    return <div><label htmlFor={id}>{label}:</label>
        {!hideSwitch && <input name={id} id={id} type="checkbox" checked={value > 0} onChange={evt => setValue(evt.target.checked ? 1 : 0)}></input>}
        <input type="number" value={value} onChange={evt => setValue(Number(evt.target.value))}></input>
    </div>
}
export default LicenseLimitation;