interface PaymentDocument {
    id: number,
    paymentMethod: string,
    dateOfPayment: Date|null,
    invoiceNumber: string,
    fileName: string,
    paymentDocumentType: string,
};
export default PaymentDocument;

export const handlePaymentDocumentsDates = (invoice: PaymentDocument) => {
    var paymentDate: any = invoice.dateOfPayment;
    invoice.dateOfPayment = (paymentDate == 0 || paymentDate == null) ? null : new Date(paymentDate * 1000);
}