interface CommonsResult {
    getCommons: () => typeof ASECLA.Commons | undefined,
    getHeaderInstance: () => ASECLA.Commons.Header | undefined,
}
export function Commons(): CommonsResult {

    const getCommons = () => {
        return ASECLA.Commons;
    }
    const getHeaderInstance = () => {
        let _commons = getCommons();
        if (_commons == undefined) return undefined;
        if (_commons.Header == undefined) return undefined;
        return _commons.Header.getInstance();
    }

    return { getCommons, getHeaderInstance };
}