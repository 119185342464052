import PageStructure from "../component/PageStructure";
import { useOrganizations } from "../hooks/useOrganizations";
import React from "react";
import { OrganizationData } from "../type/responses/OrganizationData";
import InvoiceData from "../type/InvoiceData";
import ManageOrganizationData from "../component/ManageOrganization.tsx/ManageOrganizationData";
import IsErrorMessage from "../component/utils/IsErrorMessage";
import Loading from "../component/utils/Loading";
import AseclaDataContext from "../store/AseclaDataContext";
import { ContextProps } from "../type/ContextProps";

function ManageOneOrganizationPage() {
    const props = React.useContext(AseclaDataContext) as ContextProps;
    const { isFetching, isError } = useOrganizations();

    const [ organization, setOrganization ] = React.useState<OrganizationData | null>(null);
    const [ invoiceData, setInvoiceData] = React.useState<InvoiceData | null>(null);


    React.useEffect(() => {
        let org = props.getCurrentOrganization();
        if (org !== null) {
            setOrganization(org);
            setInvoiceData(org.invoiceData);
        }
    }, [props.currentOrganizationNo]);

    if (isFetching) return <Loading></Loading>
    if (isError) return <IsErrorMessage></IsErrorMessage>
    
    return <PageStructure ><ManageOrganizationData organization={organization} invoiceData={invoiceData} setInvoiceData={setInvoiceData} onOrganizationSaved={null}/></PageStructure>
}
ManageOneOrganizationPage.CONTEXT_PATH = "manageOneOrganization";

export default ManageOneOrganizationPage;