import PaymentDocument, { handlePaymentDocumentsDates } from "./PaymentDocument";
import InvoiceData from "./InvoiceData";
import { OrganizationShort } from "./OrganizationShort";
import { UserOrderItem } from "./UserOrderItem";
import { BonusCode } from "./BonusCode";

export type UserOrder = {
    contactMail: string,
    orderId: number,
    orderTime: Date|null,
    status: string,
    userId: string,
    userEmail: string,
    items: UserOrderItem[],
    organization: OrganizationShort,
    invoiceData: InvoiceData,
    invoices: PaymentDocument[],
    bonusCode: BonusCode,
    hideBonusCode: boolean,
    organizationDiscount: number,
};

export const handleUserOrderDates = (order: UserOrder): UserOrder => {
    var t: any = order.orderTime;
    order.orderTime = t == null ? null : new Date(t * 1000);
    if (order.invoices != undefined) {
        for (let i = 0; i < order.invoices.length; i++) {
            handlePaymentDocumentsDates(order.invoices[i]);
        }
    }
    return order;
}

export const getLastInvoice = (order: UserOrder): PaymentDocument|null => {
    if (!order.invoices) return null;
    if (order.invoices.length === 0) return null;
    return order.invoices[order.invoices.length-1];
}