import { UserOrder, handleUserOrderDates } from "../UserOrder";
import { AseclaStandardResponse } from "./AseclaStandardResponse";

type BaseProps = AseclaStandardResponse;
interface OrdersResponse extends BaseProps { 
    orders: UserOrder[],
};
export default OrdersResponse;

export const handleUserOrdersResponse = (ret: OrdersResponse): OrdersResponse => {
    for (let i = 0; i < ret.orders.length; i++) {
        handleUserOrderDates(ret.orders[i]);
    }
    return ret;
}