import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from "i18next-http-backend"

import translationEN from './localization/en.json';
import translationPL from './localization/pl.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  pl: {
    translation: translationPL
  }
};

const options = {
    order: ['localStorage', 'querystring', 'navigator'],
    lookupQuerystring: 'lang',
    caches: ['localStorage']
}

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        //lng: "en",,

        fallbackLng: "en",
        detection: options,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;