import PageStructure from "../component/PageStructure";
import AseclaOfferAsHome from "../component/offer/AseclaOfferAsHome";

function AseclaOfferPage() {
    return <PageStructure><AseclaOfferAsHome/></PageStructure>
}
AseclaOfferPage.CONTEXT_PATH = "aseclaOfferPage";

export default AseclaOfferPage;

