import { Product } from "../../../type/Product";
import './ProductPropertyLabel.css';

type ProductPropertyLabelParams = {
    product: Product,
    prodNo: number,
    propNo: number,
    lang: string,
    setProductProperty: (prodNo: number, propNo: number, lang: string, value: string) => void
}

function ProductPropertyLabel({product, prodNo, propNo, lang, setProductProperty}: ProductPropertyLabelParams) {
    return <li>
        {lang}
        <input className="ProductPropertyLabel" value={product.properties[propNo].labels[lang] ?? ""} onChange={evt => setProductProperty(prodNo, propNo, lang, evt.target.value)}/>
    </li>
}
export default ProductPropertyLabel;