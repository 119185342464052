import { LicenseType } from "../../type/LicenseType";
import { PropertyQuestion } from "./PropertyQuestion";
import './LogInOrRegister.css';
import { useRegisterAnonymousOrder } from "../../hooks/mutations/useRegisterAnonymousOrder";
import { t } from "i18next";

interface LogInOrRegisterParams {
    licType: LicenseType,
    licTypePropValue?: PropertyQuestion[],
    quantity?: number,
    length?: number,
    lengthUnit?: string,
}
function LogInOrRegister({licTypePropValue, licType, quantity, length, lengthUnit}: LogInOrRegisterParams) {
    const { register: registerAnonymousOrder } = useRegisterAnonymousOrder();

    const logInToOrder = (register: boolean) => {
        registerAnonymousOrder({register, licType, quantity, length, lengthUnit, licTypePropValue});
    }

    return <div className="logInOrRegister">
        <table >
            <tbody>
                <tr>
                    <td onClick={(e) => logInToOrder(false)} className="shadow-box clickable" id="haveAccount">
                        <h6 className="asecla-heading">{t("I already have ASECLA account") as string}</h6>
                        <p>{t("Click here to log in") as string}</p>
                    </td>
                    <td onClick={(e) => logInToOrder(true)} className="shadow-box clickable" id="haveNoAccount">
                        <h6 className="asecla-heading">{t("I have no account yet") as string}</h6>
                        <p>{t("Register to ASECLA by creating a free acount, get all the priviliges of regular customer") as string}</p>
                        <ul>
                            <li>{t("you will see the status of your orders and the purchase history") as string}</li>
                            <li>{t("you will not have to provide your date each time you place the order") as string}</li>
                            <li>{t("the possibility of receiving discounts and promotional coupons") as string}</li>
                        </ul>
                    </td>
                    {/* <td>
                        <h6 className="asecla-heading">{t("Purchase without registering") as string}</h6>
                        <p>{t("In order to assign you your new license and inform you of having it, we need your email address. We also need to invoice your - so we need your data.") as string}</p>
                        <p>{t("Besides, if you want to use our ASECLA products, manage them, assign license between your employees, you will need to have an accont.") as string}</p>
                        <p>{t("Thus we please to order or register") as string}</p>
                    </td> */}
                </tr>
            </tbody>
        </table>
    </div>
}
export default LogInOrRegister;