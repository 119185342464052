import React from "react";
import { Notifications } from "../../integration/Notifications";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import { useMutation } from "@tanstack/react-query";
import { getDefaultRequestSettings, requestServer, ServerRequestSettings } from "../../store/ServerConnection";
import { t } from "i18next";
import { queryClient } from "../../App";
import { TANSTACK_USER_ORDERS_KEY } from "../useUserOrders";
import { apiErrorHandling } from "../useErrorHandling";

interface useUploadOrderDocumentParams {
    file: File,
    orderId: number,
    organizationId: number
}
export function useUploadOrderDocument() {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    const { successHandler } = apiErrorHandling({
        errMsg: t("Failed to upload document")
    });
    
    const { mutate: uploadDocument, isError: isUploadError, isSuccess: isUploadSuccess } = useMutation({
        mutationFn: async ({file, orderId, organizationId}: useUploadOrderDocumentParams) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('orderId', "" + orderId);
            formData.append('organizationId', "" + organizationId);
            let requestSettings: ServerRequestSettings = {
                ...getDefaultRequestSettings(),
                jsonStringify: false,
                contentType: undefined
            }

            return requestServer("uploadOrderDocument", formData, props, requestSettings);
        },
        onSuccess: successHandler((resp: any) => {
            queryClient.invalidateQueries([TANSTACK_USER_ORDERS_KEY]);
            serverSuccess(t("Document uploaded"));
        }),
        onError: (error: any) => {
            console.log(error);
            serverError(t("Upload failed - check your internet connection"))
        }
    });
  
    return { uploadDocument, isUploadError, isUploadSuccess };
}
