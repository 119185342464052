import Pagination, { PaginationParams } from './Pagination';
import DropdownCheckboxList, { DropDownOption } from '../DropdownCheckboxList';
import { HeaderDefinition, TableHeaderCell } from './definition/HeaderDefinition';
import './BigTableHeader.css';
import { useTranslation } from 'react-i18next';
import React from 'react';

export enum BigTableHeaderElementAligment {
    "Left",
    "Center",
    "Right"
}

export type BigTableHeaderElement = {
    element: JSX.Element,
    orderPriority: number,
    aligment: BigTableHeaderElementAligment
}

export type BigTableHeaderParams = {
    allHeaders: HeaderDefinition[],
    paginationParams: PaginationParams,
    switchColumnVisibility: (headerKey: string) => void,
    showPageSize?: boolean,
    showPagination?: boolean,
    otherButtons?: JSX.Element[],
    otherProps?: any,
}

/**
 * This function is to implement a header/div over the table (where all the buttons or eg pagination is placed)
 */
function BigTableHeader({switchColumnVisibility, allHeaders, otherButtons, paginationParams, showPageSize, showPagination, otherProps}: BigTableHeaderParams) {
    const {t} = useTranslation();

    const handleSelect = (changedOption: DropDownOption) => {
        switchColumnVisibility(changedOption.headerKey);
    };

    const getHeaderString = (header: string | TableHeaderCell): string => {
        if ((typeof header) == "string")
            return header as string;
        else {
            return (header as TableHeaderCell).getString();
        }
    }
    
    let options: DropDownOption[] = [];
    for (let i = 0; i < allHeaders.length; i++) {
        let h: HeaderDefinition = allHeaders[i];
        if (!(h.alwaysDisplay ?? false) && !(h.alwaysHide ?? false)) {
            options.push({ label: getHeaderString(h.displayedHeader), value: "option_" + i, isChecked: h.display, headerKey: h.headerKey });
        }
    }

    let otherPropsButtonsRight: JSX.Element[] = [];
    if (otherProps && otherProps.otherButtonsRight) {
        otherPropsButtonsRight = otherProps.otherButtonsRight;
    }
    
    return <div className="bigTableHeader">
        <span>
            {otherButtons != undefined && otherButtons.map((ob, index) => <React.Fragment key={"headerEl" + index}>{ob}</React.Fragment>)}
        </span>

        <span>
            {otherPropsButtonsRight.map((ob, index) => <React.Fragment key={"headerElRight" + index}>{ob}</React.Fragment>)}
            <DropdownCheckboxList buttonText={t("Choose columns")} options={options} onSelect={handleSelect}></DropdownCheckboxList>

            {paginationParams.noOfPages != null && paginationParams.noOfPages > 1 &&
                <Pagination 
                    currentPageNo={paginationParams.currentPageNo}
                    gotoPage={paginationParams.gotoPage}
                    nextPage={paginationParams.nextPage}
                    paginationData={paginationParams.paginationData}
                    canPreviousPage={paginationParams.canPreviousPage}
                    canNextPage={paginationParams.canNextPage}
                    previousPage={paginationParams.previousPage}
                    setPageSize={paginationParams.setPageSize}
                    setCurrentPageNo={paginationParams.setCurrentPageNo}
                    pageSize={paginationParams.pageSize}
                    noOfPages={paginationParams.noOfPages}
                    showPageSize={showPageSize}
                    showPagination={showPagination}
                ></Pagination>
            }
        </span>
    </div>
}

export default BigTableHeader;