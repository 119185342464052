
export interface BonusCode {
    id: number;
    discountType: string|null;
    value: number|null;
    limitNoOfUsage: number|null;
    noOfUsage: number|null;
    validUntil: Date|null;
    createdBy: string|null;
    creationDate: Date|null;
    theCode: string|null;
    bonusDescription: any;
    products: number[];
    active: boolean;
    finalVersion: boolean;
}

export const handleBonusCodeDates = (bonusCode: BonusCode): BonusCode => {
    if (bonusCode.validUntil != null) {
        bonusCode.validUntil = new Date(bonusCode.validUntil + "");
    }
    return bonusCode;
}

export const handleBonusCodesDates = (bonusCodes: BonusCode[]): BonusCode[] => {
    for (var i = 0; i < bonusCodes.length; i++) {
        handleBonusCodeDates(bonusCodes[i]);
    }
    return bonusCodes;
}