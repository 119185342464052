import { AseclaStandardResponse } from "./AseclaStandardResponse";
import { OrganizationData as OrganizationData, handleOrganizationDataResponse } from "./OrganizationData";

type BaseProps = AseclaStandardResponse;
interface ListOrganizationsResponse extends BaseProps { 
    organizations: OrganizationData[],
    count: number
};
export default ListOrganizationsResponse;

export const handleListOrganizationsResponse = (ret: ListOrganizationsResponse): ListOrganizationsResponse => {
	for (let orgNo = 0; orgNo < ret.organizations.length; orgNo++) {
		let org: OrganizationData = ret.organizations[orgNo];
		handleOrganizationDataResponse(org);
	}
	return ret;
}