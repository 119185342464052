import { useQuery } from "@tanstack/react-query";
import { getUrl, prepareHeadersPromise } from "../store/ServerConnection";
import { LicenseType } from "../type/LicenseType";
import { LicenseTypeListResponse } from "../type/responses/LicenseTypeListResponse";
import { Product } from "../type/Product";
import React from "react";
import AseclaDataContext from "../store/AseclaDataContext";
import { LicenseStatsResponse } from "../type/responses/LicenseStatsResponse";
import { LicenseStats } from "../type/LicenseStats";

export const TANSTACK_LICENSE_TYPES_STATS_KEY = [ "LICENSE_TYPES", "STATS" ];
export const TANSTACK_LICENSE_TYPES_OFFERED = [ "LICENSE_TYPES", "OFFERED" ];

interface useLicenseTypesFullSetResult {
    licenseStats: LicenseStats[]
    products: Product[]
    loaded: boolean,
    isError: boolean
}
export function useLicenseTypesFullSet(): useLicenseTypesFullSetResult {
    const { authentication } = React.useContext(AseclaDataContext);

    let query = useQuery<LicenseStatsResponse>({
        queryKey: TANSTACK_LICENSE_TYPES_STATS_KEY,
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            if (authentication == null || !authentication.initialized() || !authentication.isAuthenticated()) return null;

            let action = "listAllLicenseTypes";
            let url = getUrl(action);
            let head = await prepareHeadersPromise(null, authentication);
            const requestOptions = {
                method: 'POST',
                headers: head,
            };
            const response = await fetch(url, requestOptions);
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return response.json();
        }
    });


    const getLicenseTypeOrder = (lt1: LicenseType, lt2: LicenseType): number => {
        if (lt1.id === undefined && lt2.id !== undefined) {
            return 1;
        }
        if (lt1.id !== undefined && lt2.id === undefined) {
            return -1;
        }
        if (lt1.product != null && lt2.product != null && lt1.product.names["en"] > lt2.product.names["en"])
            return 1;
        else if (lt1.product != null && lt2.product != null && lt1.product.names["en"] < lt2.product.names["en"])
            return -1;
        else if (lt1.id! > lt2.id!)
            return 1;
        else if (lt1.id! < lt2.id!)
            return -1;
        return 0;
    }

    return {
        licenseStats: query.data == undefined ? [] : query.data.licenseStats.sort((ls1, ls2) => getLicenseTypeOrder(ls1.licenseType, ls2.licenseType)),
        products: query.data == undefined ? [] : query.data.products,
        loaded: !query.isLoading,
        isError: query.isError,
    }
}

interface useLicenseTypesOfferedResult {
    licenseTypes: LicenseType[]
    isLoading: boolean,
    isError: boolean
    isFetched: boolean
}
export function useLicenseTypesOffered(): useLicenseTypesOfferedResult {
    let query = useQuery<LicenseTypeListResponse>({
        queryKey: TANSTACK_LICENSE_TYPES_OFFERED,
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            let action = "listCodesToBuy";
            let url = getUrl(action);
            let head = await prepareHeadersPromise(null);
            const requestOptions = {
                method: 'POST',
                headers: head,
            };
            const response = await fetch(url, requestOptions);
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return response.json();
        }
    });

    return {
        licenseTypes: query.data == undefined ? [] : query.data.licenses,
        isLoading: query.isFetching,
        isError: query.isError,
        isFetched: query.isFetched
    }
}
