import { UserOrderHistory, handleUserOrderHistoryDates } from "../UserOrderHistory";
import { AseclaStandardResponse } from "./AseclaStandardResponse";

type BaseProps = AseclaStandardResponse;
interface UserOrderHistoryResponse extends BaseProps { 
    history: UserOrderHistory[],
};
export default UserOrderHistoryResponse;

export const handleUserOrderHistoryResponse = (resp: UserOrderHistoryResponse): UserOrderHistoryResponse => {
    for (let i = 0; i < resp.history.length; i++) {
        handleUserOrderHistoryDates(resp.history[i]);
    }
    return resp;
}